import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Banner from "../components/Banner"

const NotFoundPage = function ({ data: { settingsJson }, location }) {
  const { title } = settingsJson.errors.notFound404

  return (
    <Layout>
      <Seo title={title} pathname={location.pathname} />
      <Banner content={`<h1>${title}</h1>`} />
      <section className="container text-center">
        <h2>{title}</h2>
        <p>{settingsJson.errors.notFound404.explanation}</p>
        <Link to="/" title={settingsJson.backToHome}>
          {settingsJson.backToHome}
        </Link>
      </section>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPageQuery {
    settingsJson {
      backToHome
      errors {
        notFound404 {
          title
          explanation
        }
      }
    }
  }
`
